import React from 'react'
import Header from './header'
import Footer from './footer'
import NavBar from './navbar'
//import '../styles/index.scss'
import layoutStyles from  './layout.module.css'

const Layout = (props) => {
    return (
        <div>
            <NavBar></NavBar>            
            <Header></Header>
            <div className={layoutStyles.container}>
                <div className={layoutStyles.content}>
                    {props.children}
                </div>
            </div>
            
        <Footer> </Footer>
        </div>
    )
}

export default Layout
