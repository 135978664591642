import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

import NavBar from './navbar'


//import './header.module.scss'
import headerStyles from './header.module.css'

const Header = () => {
  const data = useStaticQuery(graphql`query{
        site{
          siteMetadata{
            title
          }
        }
      }
      `)
  return (
    <header className={headerStyles.header} >      
      
      <h1 ><Link style={{ boxShadow: `none` }} className={headerStyles.title} to="/">{data.site.siteMetadata.title}</Link></h1>
      <a style={{boxShadow: `none`}} className={headerStyles.backgroundAttribution} href="https://https://freesvg.org/" target="_blank">Banner by freeSVG</a>
    </header>
  )
}


export default Header