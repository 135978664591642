import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import navbarStyles from  './navbar.module.css'
import headerStyles from  './header.module.css'


const NavBar = () => {
    return (
  
    <nav className={navbarStyles.navigationBar}>

    {/* 
        A button which is only visible for narrow "windows".
        It's a decorated checkbox - the clickable label providing
        the "icon" or decoration and the tue checkbox being hidden.
        
        The navigation items are an unordered list. The list
        itself is a flexbox container with each item beinbg a flex item.
    */}


    <input className={navbarStyles.burgerButtoncheckbox} type="checkbox" role="Menu" aria-label="Menu" aria-controls="navigation" aria-haspopup="true" id="menu"></input>
    <label className={navbarStyles.burgerButton} for="menu"><span className={navbarStyles.burgerBar}></span></label>
    
    <ul className={navbarStyles.flexContainer}>
        <li className={navbarStyles.flexItem}>
            <Link className={navbarStyles.linkItem} activeClassName={navbarStyles.linkItemActive} to="/">Home</Link>
        </li>
        <li className={navbarStyles.flexItem}>
            <Link className={navbarStyles.linkItem} activeClassName={navbarStyles.linkItemActive} to="/blog">Blog</Link>
        </li>
        <li className={navbarStyles.flexItem}>
            <Link className={navbarStyles.linkItem} activeClassName={navbarStyles.linkItemActive} to="/contact">Contact</Link>
        </li>
        <li className={navbarStyles.flexItem}>
            <Link className={navbarStyles.linkItem} activeClassName={navbarStyles.linkItemActive} to="/about">About</Link>
        </li>
    </ul>  
  </nav>
)

}


export default NavBar